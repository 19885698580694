@import 'mixins.scss';
.Banner {
  position: relative;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient);

  &__titleSM {
    position: absolute;
    top: 105px;
  }

  &__bgicon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    object-fit: contain;
    transform: translate(-50%, -50%);
    z-index: 2;

    &__sm {
      height: 150px;
      top: calc(50% - 25px);
    }
  }

  &__background {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: soft-light;

    picture {
      position: absolute;
      width: 80%;
      max-width: 1400px;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  h1 {
    font-family: var(--ff-title);
    font-weight: var(--fw-bold);
    font-size: var(--fz-xl);
    color: #fff;
    z-index: 2;
    text-align: center;
    padding: 0 var(--tot-padding);
  }

  @include small {
    height: 20vh;

    h1 {
      top: 50%;
      font-size: var(--fz-md);
      text-align: center;
    }

    &__bgicon {
      $iconsizeh: 12vh;
      height: $iconsizeh;
      width: $iconsizeh;

      &__sm {
        height: $iconsizeh;
        top: calc(50% + 1vh);
      }
    }
  }
}
