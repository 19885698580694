@import 'mixins.scss';
.Custom404 {
  background: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 0 var(--tot-padding);

  h2 {
    font-family: var(--ff-title);
    font-size: var(--fz-lg);
    font-weight: var(--fw-bold);
    color: var(--h2-color);
    margin-bottom: 10px;
  }

  p {
    color: var(--darkGrey);
    margin-bottom: 40px;
    text-align: center;
  }

  a {
    padding: 20px 40px;
    background: var(--purple);
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 40px;
  }

  video {
    max-width: 100%;
  }

  @include small {
    video {
      padding-bottom: var(--tot-padding);
    }
  }
}
